import React, { useState } from 'react'
import { graphql } from 'gatsby'
import Seo from '@seo'
import Slider from '@slider'
import Button from '@button'
import { useWorks } from '@hooks/useWorks'
import { PageProps } from '@types'
import '@sass/components/HomeContainer.scss'

const Index = ({ data: { contentfulPage } }: PageProps) => {
  const [activeIndex, setActiveIndex] = useState(0)

  const works = useWorks()
  const { pageTitle, pageDescription, pageColor } = contentfulPage

  return (
    <div className="columns is-multiline home-page">
      <Seo title={pageTitle} description={pageDescription} themeColor={pageColor} />
      <Slider
        className="home-slider"
        animate
        mouseControl
        settings={{
          direction: 'vertical',
          effect: 'fade',
          preloadImages: false,
          lazy: true,
        }}
        onChange={(swiper) => setActiveIndex(swiper.activeIndex)}
      >
        {works.map(({ id, slug, title, text, image }) => (
          <div key={id} className="home-slider__slide swiper-lazy" data-background={image}>
            <div className="swiper-lazy-preloader swiper-lazy-preloader-white" />
            <div className="slide__content">
              <h2 className="slide__title animate">{title}</h2>
              <p className="slide__text animate delay-0-25s w-mb-100">{text}</p>
              <Button href={slug} title={title} className="animate fadeIn delay-0-5s has-arrow">
                Read more
              </Button>
            </div>
          </div>
        ))}
      </Slider>
      {activeIndex + 1 !== works.length && (
        <div className="scroll-indicator">
          <span />
        </div>
      )}
      <div className="indicators">
        <span>0{activeIndex + 1}</span>
        <span>0{works.length}</span>
      </div>
    </div>
  )
}

export default Index

export const query = graphql`
  query HomeQuery($homePageId: String!) {
    contentfulPage(contentful_id: { eq: $homePageId }) {
      id
      pageTitle
      pageDescription
      pageColor
    }
  }
`
